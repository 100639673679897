import lozad from 'lozad';

(function () {
    // Get all images
    let allImages = [...document.querySelectorAll('img'), ...document.querySelectorAll('picture')];

    const excludeClassesFromLozad = ['nolozad', 'logo__image'];

    allImages = allImages.filter(image => {
        if (!excludeClassesFromLozad.some(className => image.classList.contains(className))) {
            image.classList.add('lozad');
            return true;
        }

        // Undo if you want to see the excluded DOM images.

        // else {
        //     console.log(image);
        // }
    });

    const observer = lozad(allImages.forEach(img => img));
    observer.observe();
})();
