class hamburgerMenu {
    constructor(iconToggle, menuContainer) {
        this.iconsToggle = document.querySelectorAll(iconToggle); // menu icon
        this.menuContainer = menuContainer; // menu nav container
        this.menu = menuContainer.querySelector(".menu");
        this.menuLinks = this.menu?.querySelectorAll(".menu__link");
        this.expanded = false;

        if (this.iconsToggle && this.menuContainer) {
            // check if contains elements
            this.init();
        } else {
            return;
        }
    }

    init() {
        this.setAriaAttribute(this.menu, "aria-expanded", this.expanded);
        this.clickEvent();
    }

    clickEvent() {
        for (const iconToggle of this.iconsToggle) {
            iconToggle.addEventListener("click", () => {
                this.menuToggle();
            });
        }
    }

    menuTabIndexToggle() {
        if (!this.expanded) {
            this.menuLinks.forEach((item) =>
                item.setAttribute("tabindex", "-1")
            );
        } else {
            this.menuLinks.forEach((item) => item.removeAttribute("tabindex"));
            this.focustrap();
        }
    }

    focustrap() {
        document.addEventListener("keyup", (e) => {
            if (e.key === "Tab" || e.key === "ShiftKey") {
                if (!e.target.closest("[data-menu-container]")) {
                    this.menuContainer.children[0].focus();
                }
            }
        });
    }

    setAriaAttribute(element, attribute, value) {
        element?.setAttribute(attribute, value);
    }

    getAriaAttribute(element, attribute = "aria-expanded") {
        return element?.getAttribute(attribute);
    }

    toggleAriaExpanded(iconToggle) {
        const toggleState =
            iconToggle.getAttribute("aria-expanded") === "true"
                ? (this.expanded = "false")
                : (this.expanded = "true");
        iconToggle.setAttribute("aria-expanded", this.expanded);
        this.menu.setAttribute("aria-expanded", this.expanded);
    }

    menuOverlay() {
        this.mobileOverlay.addEventListener("click", () => {
            this.menuToggle();
        });
    }

    menuToggle() {
        this.removeMenuSectionStates();

        for (const iconToggle of this.iconsToggle) {
            iconToggle.classList.toggle("is-active");
            this.toggleAriaExpanded(iconToggle);
        }

        this.menuContainer.classList.toggle("is-active");

        this.menuSectionListener();
        this.menuTabIndexToggle();
    }

    removeMenuSectionStates() {
        this.menuContainer
            .querySelectorAll("[data-menu-section]")
            .forEach((section) => {
                section.classList.remove("is-active");
            });
    }

    menuSectionListener() {
        this.menuContainer.addEventListener("click", this.sectionToggle);
    }

    sectionToggle(e) {
        const menuItemAttr = "data-menu-toggle";
        const menuReturnButton = "data-menu-return";

        if (e.target.classList.contains("icon--dropdown")) {
            if (e.target.parentNode.querySelector("[data-menu-section]")) {
                e.target.parentNode
                    .querySelector("[data-menu-section]")
                    .classList.add("is-active");
            }
        }

        if (
            e.target.classList.contains("btn-return") ||
            e.target.parentNode.classList.contains("btn-return")
        ) {
            e.target
                .closest("[data-menu-section]")
                .classList.remove("is-active");
        }
    }
}

new hamburgerMenu(
    "[data-menu-icon]",
    document.querySelector("[data-menu-container]")
);