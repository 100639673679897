import './scripts/icons';
import './scripts/_default';
import './scripts/accordion';
import './scripts/editor';
import './scripts/hamburger';
import './scripts/helper';
import './scripts/infinite-scroll';
import './scripts/lozad';
import './scripts/meta-theme';
import './scripts/notifications';
import './scripts/swiper';
import './scripts/tabs';
