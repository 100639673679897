import Swiper from 'swiper/bundle';

(function () {
    const breakpoints = {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1280,
        xxl: 1680,
    };

    const effects = [
        'slide',
        'fade',
        'cube',
        'coverflow',
        'flip',
        'creative',
        'cards'
    ];

    const swiperSizes = {
        fourth: 4,
        half: 2,
        third: 3,
        full: 1,
    };

    const swiperOptions = {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        autoHeight: false,
        slideClass: 'swiper__slide',
        createElements: true,
        watchOverflow: true,

        lazy: {
            loadPrevNext: true,
            loadOnTransitionStart: true,
        },


        // Navigation arrows
        navigation: {
            nextEl: '.swiper__button--next',
            prevEl: '.swiper__button--prev',
            disabledClass: 'swiper__button--disabled'
        },

        speed: 500,
        spaceBetween: 40,
        effect: effects[0],
        grabCursor: true,

        breakpoints: {
            [`${breakpoints.xs}`]: {
                slidesPerView: 1.2,
                centeredSlides: true,
            },
            [`${breakpoints.lg}`]: {
                slidesPerView: 2,
                centeredSlides: false,
            }
        }
    };

    const swipers = [...document.querySelectorAll('.swiper:not(.swiper--timeline-thumbs):not(.swiper--timeline)')];


    swipers.forEach(swiper => {
        // =-=-=-=-=-=-=-=-=-=-=-=- Begin default swiper carousels =-=-=-=-=-=-=-=-=-=-=-=-
        Object.entries(swiperSizes).forEach(function (swiperSize) {
            const [key, value] = swiperSize;
            if (swiper.classList.contains(`swiper--${key}`)) {
                swiperOptions.breakpoints[`${breakpoints.lg}`] = {
                    slidesPerView: value,
                    centeredSlides: false,
                };
            }
        });

        if (swiper.classList.contains('swiper--posts')) {
            swiperOptions.autoHeight = false;
            swiperOptions.loop = false;

            swiperOptions.breakpoints[`${breakpoints.lg}`] = {
                slidesPerView: 4,
            };
        }

        if (swiper.classList.contains('swiper--image')) {
            swiperOptions.autoHeight = false;
            swiperOptions.autoplay = false;
            swiperOptions.speed = 500;
            swiperOptions.lazy = {
                loadPrevNext: true,
                loadOnTransitionStart: true,
            };

            swiperOptions.breakpoints[`${breakpoints.xs}`] = {
                slidesPerView: 1,
                spaceBetween: 20,
            }

            swiperOptions.breakpoints[`${breakpoints.lg}`] = {
                slidesPerView: 1,
                spaceBetween: 20,
            };

        }

        // =-=-=-=-=-=-=-=-=-=-=-=- End default swiper carousels =-=-=-=-=-=-=-=-=-=-=-=-
        // =-=-=-=-=-=-=-=-=-=-=-=- Begin Override swiper carousels =-=-=-=-=-=-=-=-=-=-=-=-
        // If you want to overwrite a swiper carousel do it between the "Begin overwrite swiper carousels" and "End overwrite swiper carousels"

        if (swiper.classList.contains('swiper--hero')) {
            swiperOptions.autoHeight = false;
            swiperOptions.slidesPerView = 1;

            // Overrides the default breakpoint options with nothing, so the above options are used
            swiperOptions.breakpoints[`${breakpoints.xs}`] = {};
            swiperOptions.breakpoints[`${breakpoints.lg}`] = {};
        }

        if (swiper.classList.contains('swiper--logo')) {
            swiperOptions.loop = true;
            swiperOptions.autoHeight = false;
            swiperOptions.autoplay = {
                delay: 1,
            }
            swiperOptions.freeMode = false;
            swiperOptions.slidesPerView = 6.75;
            swiperOptions.speed = 7500;
            swiperOptions.preventInteractionOnTransition = false;
            swiperOptions.grabCursor = false;
            swiperOptions.allowTouchMove = false;
            swiperOptions.centeredSlides = true;

            // swiperOptions.navigation = {
            //     nextEl: swiper
            //         .closest(".pane")
            //         .querySelector(".swiper__button--next"),
            //     prevEl: swiper
            //         .closest(".pane")
            //         .querySelector(".swiper__button--prev"),
            //     disabledClass: "swiper__button--disabled",
            // };

            swiperOptions.breakpoints[`${breakpoints.xs}`] = {
                slidesPerView: 2.5,
                spaceBetween: 40,
            }

            swiperOptions.breakpoints[`${breakpoints.lg}`] = {
                slidesPerView: 6.75,
                spaceBetween: 60,
            };

        }

        // =-=-=-=-=-=-=-=-=-=-=-=- End Override swiper carousels =-=-=-=-=-=-=-=-=-=-=-=-

        const swiperDefault = new Swiper(swiper, swiperOptions);

    });
})();


var swiperThumbs = new Swiper(".swiper--timeline-thumbs", {
    spaceBetween: 18,
    slidesPerView: 'auto',
    watchSlidesProgress: true,
    slideClass: 'swiper__slide',
    direction: 'horizontal',
    autoHeight: true,
    lazy: {
        loadPrevNext: true,
    },
});

const container = document.querySelector('.swiper--timeline');
const offset = container.offsetWidth * 0.25;

var swiper2 = new Swiper(".swiper--timeline", {
    spaceBetween: 40,
    slideClass: 'swiper__slide',
    allowTouchMove: true,
    direction: 'horizontal',
    slidesPerView: 1.8,
    centerInsufficientSlides: true,

    lazy: {
        loadPrevNext: true,
    },

    thumbs: {
        swiper: swiperThumbs,
    },

    breakpoints: {
        ['0']: {
            slidesPerView: 1,
            centeredSlides: true,
        },
        ['992']: {
            slidesPerView: 1.8,
            centeredSlides: false,
            slidesOffsetAfter: offset 
        }
    }
});
